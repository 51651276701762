export const language = {
    CN: '中文', // 中文
    EN: 'English', // 英文
    anjun: 'This query system is subordinate to the website of Anjun logistics', // 安骏所属
    trackQuery: 'Track & Trace', // 轨迹查询
    submit: 'Submit', // 马上查询
    returnToHome: 'Return to home', // 返回首页
    register: 'register', // 注册
    placeholder: 'Please enter the waybill number to query the logistics trajectory, and support multiple tracking numbers', // 轨迹查询输入框placeholder
    allTracks: 'All tracks', // 全部轨迹
    queryNotFound: 'Query not found', // 查询不到轨迹
    duringTransportation: 'In transit', // 运输途中
    download: 'Arrived but not claimed', // 到达待取
    deliveryFailed: 'Delivery failed', // 投递失败
    signedIn: 'Signed in', // 成功签收
    customerService: 'service', // 客服
    forecast: 'forecast', // 已预报
    airTakeOff: 'Air take off', // 航空起飞
    arrivalAtDestinationAirport: 'Arrival at destination airport', // 到达目的国机场
    delivered: 'delivered', // 已签收
    beCareful: 'be careful', // 注意
    upToQueries: 'Only the first 50 items are queried each time' // 每次只查询前50条
};
