import Vue from 'vue';
// import './plugins/axios';
import App from './App.vue';
import router from './router';
import './assets/common.css';
// 引入国际化语言
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: 'zh-CN',    // 语言标识
    //this.$i18n.locale // 通过切换locale的值来实现语言切换
    messages: {
        'zh-CN': require('./common/lang/ch'),   // 中文语言包
        'en-US': require('./common/lang/en'),   // 英文语言包
        'pu-PT': require('./common/lang/pt'),   // 英文语言包
    }
});

Vue.config.productionTip = false;

import defaultSetting from '../setting';
router.beforeEach((to, from, next) => {
  // 用于设置 浏览器的 title 显示
  if (to.meta.title) {
    document.title = `${to.meta.title}`
  } else {
    document.title = `${defaultSetting .title}`
  }
  next()
})
new Vue({
  router,
    i18n,
  render: h => h(App)
}).$mount('#app');
