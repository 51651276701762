import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

// import HelloWorld from '@/views/trajectory/index';
export const routes = [
    {
        path: '/',
        redirect: '/trajectory',
        // component: HelloWorld
    },
    {
        path: '/trajectory',
        name: 'trajectory',
        component: () => import('@/views/trajectory/index.vue'),
        meta: { title: '轨迹查询' },
        hidden: true
    },
    {
        path: '/trackQuery',
        name: 'trackQuery',
        component: () => import('@/views/trajectory/com/trackQuery.vue'),
        meta: { title: '轨迹查询' },
        hidden: true
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
