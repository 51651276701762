export const language = {
    CN: 'Chinês', // 中文
    EN: 'Inglês', // 英文
    anjun: 'O sistema de consulta está sob a site Anjun Logística', // 安骏所属
    trackQuery: 'Consultar rastreamento', // 轨迹查询
    submit: 'Consultar já', // 马上查询
    returnToHome: 'Voltar para página inicial', // 返回首页
    register: 'Registrar-se', // 注册
    placeholder: 'Favor digitar o número do pedido logístico para consultar o tracking, suportar a consultar por vários números de pedido nas linhas novas', // 轨迹查询输入框placeholder
    allTracks: 'Todos', // 全部轨迹
    queryNotFound: 'Não é possível consultar', // 查询不到轨迹
    duringTransportation: 'Em trânsito', // 运输途中
    download: 'Chegando para ser pego', // 到达待取
    deliveryFailed: 'Entrega falhada', // 投递失败
    signedIn: 'Entrega bem-sucedida', // 成功签收
    customerService: 'QQ do atendimento ao cliente', // 客服
    forecast: 'Previsto', // 已预报
    airTakeOff: 'Decolagem aérea', // 航空起飞
    arrivalAtDestinationAirport: 'Chegada ao aeroporto de destino', // 到达目的国机场
    delivered: 'Assinado', // 已签收
    beCareful: 'Nota', // 注意
    upToQueries: 'Apenas os primeiros 50 itens são consultados cada vez'
};